<template>
  <div class="goods">
    <van-nav-bar
      :fixed="true"
      left-arrow
      placeholder
      @click-left="onClickLeft"
      :title="$t('Financing.ServiceSu.Service')"
    />
    <div class="customer-ser">
      <div class="Customer">{{ $t("Financing.ServiceSu.Customer") }}</div>
      <div class="Ifyou">{{ $t("Financing.ServiceSu.Ifyou") }}</div>
    </div>
    <div class="customer-img">
      <img src="@/assets/Img/Service/ia_100000000.png" alt="" />
    </div>
    <!-- 客服电话，服务时间 -->
    <div class="contact">
      <div
        class="contact-div"
        v-for="item in contactData"
        :key="item.id"
        @click="awaken"
      >
        <div class="contact-test">{{ $t(item.test) }}</div>
        <div class="contact-img">
          <img src="@/assets/Img/Service/ia_100000004.png" alt="" />
        </div>
        <!-- <div class="contact-tel">{{ item.telephone }}</div> -->
        <div class="contact-times">
          <div class="contact-Icon">
            <img src="@/assets/Img/Service/ia_100000006.png" alt="" />
          </div>
          <div class="contact-time">{{ item.time }}</div>
        </div>
      </div>
    </div>
    <!-- 其他联系方式 -->
    <!-- <div class="Dedicated">
      <div class="Dedicated-ser">{{ $t("Financing.ServiceSu.Dedicated") }}</div>
      <div class="Dedicated-div" v-for="items in DedicatedData" :key="items.id">
        <div class="Dedicated-img">
          <img src="@/assets/Img/Service/ia_100000008.png" alt="" />
        </div>
        <div class="Dedicated-sm">
          <div class="Dedicated-text" @click="Dialogue">{{ $t(items.text) }}</div>
          <div class="Dedicated-Jobnum">{{ $t(items.Jobnum) }}</div>
        </div>
      </div>
    </div> -->
    <!-- 联系客服 -->
    <van-button @click="Dialogue" class="van-button" type="primary" block>{{$t('Financing.ServiceSu.Online')}}</van-button>
  </div>
</template>
<script>
import Vue from "vue";
import { NavBar, Button  } from "vant";
Vue.use(NavBar);
Vue.use(Button);
export default {
  name: "Service",
  components: {
    [NavBar.name]: NavBar,
  },
  data() {
    return {
      // 电话数据
      contactData: [
        {
          test: "Financing.ServiceSu.Layanana8",
          telephone: "+6283131586675",
          time: "10:00-23:59",
        },
        {
          test: "Financing.ServiceSu.Layanana9",
          telephone: "+6283871993604",
          time: "10:00-23:59",
        },
      ],
      //   其他联系方式
      DedicatedData: [
        {
          text: "Financing.ServiceSu.Telegram8",
          Jobnum: "Financing.ServiceSu.Telegram008",
        },
        {
          text: "Financing.ServiceSu.Telegram9",
          Jobnum: "Financing.ServiceSu.Telegram009",
        },
      ],
    };
  },
  methods: {
    //跳转客服对话页面
    Dialogue(){
      this.$router.push('DialoguePage')
    },
    // 唤醒
    awaken() {
      // window.android.takePhoto();
      let last = Date.now();
      var frame = document.createElement("a");
      frame.src = "messenger:// messenger.telegram.org";
      frame.style.display = "block";
      document.body.appendChild(frame);
      setTimeout(function () {
        document.body.removeChild(frame);
      }, 4);
      window.location.href='messenger:// messenger.telegram.org'
      // setTimeout(function () {
      //   if (Date.now() - last > 1000) {
          
      //     window.location.href = "messenger:// messenger.telegram.org"
      //   }
      // }, 1200);
    },
    onClickLeft() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="less" scoped>
// 头部说明样式
.customer-ser {
  width: 100%;
  background-color: #bac3d2;
  position: relative;
}
.Customer {
  font-size: 1.2rem;
  margin-left: 8%;
  padding-top: 1rem;
  font-weight: 600;
}
.Ifyou {
  font-size: 0.7rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-left: 8%;
  color: rgb(59, 58, 58);
}
.Ifyou,
.Customer {
  width: 50%;
}
.customer-img {
  width: 50%;
  max-height: 50%;
  position: absolute;
  right: 0;
  top: 12%;
  z-index: 1;
  img {
    width: 100%;
    height: 100%;
  }
}
// 电话客服样式
.contact {
  width: 93vw;
  display: flex;
  margin: auto;
  justify-content: space-between;
  margin-top: 12.5vw;
  .contact-div {
    width: 45%;
    z-index: 2;
    background-color: #f6f7f7;
    border-radius: 5px;
    padding: 0.3rem;
  }
}
.contact-test {
  font-size: 0.9rem;
  font-weight: 700;
  margin: 0.5rem 0;
}
.contact-tel {
  font-size: 0.8rem;
  margin: 0.3rem;
}
.contact-img {
  width: 100%;
  img {
    width: 100%;
  }
}
.contact-times {
  width: 90%;
  display: flex;
  .contact-time {
    width: 90%;
    font-size: 0.8rem;
  }
}
.contact-Icon {
  width: 1rem;
  margin-right: 0.3rem;
  img {
    width: 100%;
  }
}
// 其他联系方式样式
.Dedicated {
  width: 90vw;
  margin: auto;
  margin-top: 1rem;
  background-color: #f6f7f7;
  border: 1px solid #f6f7f7;
  border-radius: 5px;
  .Dedicated-ser {
    font-size: 1rem;
    margin: 0.5rem;
  }
}
.Dedicated-div {
  width: 95%;
  margin: auto;
  padding: 0.5rem;
  display: flex;
  align-items: center;
}
.Dedicated-img {
  width: 2rem;
  margin-right: 0.3rem;
  img {
    width: 100%;
  }
}
.Dedicated-sm {
  width: calc(100% - 2.3rem);
  height: 100%;
  line-height: 100%;
  .Dedicated-text {
    width: 100%;
    font-size: 0.8rem;
  }
  .Dedicated-Jobnum {
    width: 100%;
    font-size: 0.6rem;
    color: #999;
  }
}
.van-button{
  width: 92vw;
  margin: auto;
  margin-top: 1rem;
  border-radius: 25px;
  background-color: #30394a;
  border: 0;
}
</style>